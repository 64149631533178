<template>
  <div style="height: inherit">

    <!-- Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="d-flex justify-content-between align-items-center">
            <!--            <div class="result-toggler">-->
            <!--              <feather-icon-->
            <!--                icon="MenuIcon"-->
            <!--                class="d-flex d-lg-none mr-2"-->
            <!--                size="22"-->
            <!--                @click="mqShallShowLeftSidebar = true"-->
            <!--              />-->

            <!--            </div>-->
            <div
              class="d-flex justify-content-start align-items-center "
            >
              <span v-if="totalLocations!==null">
                {{ $tc("location.locations_found", totalLocations) }}
              </span>

              <span v-else>{{ $t('location.location_finder') }}</span>
            </div>
            <div class="view-options d-flex">
              <!-- Per Page -->
              <!--              <b-col>-->
              <!--                <label>Show</label>-->
              <!--                <v-select-->
              <!--                  v-model="filters.perPage"-->
              <!--                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
              <!--                  :options="filterOptions.perPage"-->
              <!--                  :clearable="false"-->
              <!--                  class="per-page-selector d-inline-block mx-50"-->
              <!--                />-->
              <!--                <label>entries</label>-->
              <!--              </b-col>-->
              <!-- Sort Button -->
              <!--              <b-dropdown-->
              <!--                v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
              <!--                :text="$t(sortBy.text)"-->
              <!--                right-->
              <!--                variant="outline-primary"-->
              <!--              >-->
              <!--                <b-dropdown-item-->
              <!--                  v-for="sortOption in sortByOptions"-->
              <!--                  :key="sortOption.value"-->
              <!--                  @click="sortBy=sortOption"-->
              <!--                >-->
              <!--                  {{ $t(sortOption.text) }}-->
              <!--                </b-dropdown-item>-->
              <!--              </b-dropdown>-->

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- TOP Sidebar -->
    <!--    <location-list-top-sidebar-->
    <!--      :filters="filters"-->
    <!--      :filter-options="filterOptions"-->
    <!--    />-->
    <!-- Overlay -->
    <b-overlay
      :show="submittingFetch"
      rounded
      opacity="0.6"
    >
      <b-row class="my-1">
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class=" text-uppercase"
            block
            @click="$router.push({ name: 'apps-location-add'})"
          >
            {{ $t('location.add') }}
          </b-button>
        </b-col>
      </b-row>
      <!-- Locations -->

      <section v-if="itemView==='map-view'">
        <b-card
          no-body
          class="my-2 p-1"
        >
          <location-list-map-view
            :data="locations"
            :filters="filters"
          />
        </b-card>
      </section>
      <section
        v-else
        :class="itemView"
      >
        <b-card
          v-for="location in locations"
          :key="location.id"
          class="ecommerce-card"
          no-body
        >
          <div class="item-img justify-content-center">
            <!--            <ProfileAvatar-->
            <!--              :location="location"-->
            <!--            />-->
          </div>

          <!-- Details -->
          <b-card-body>
            <div class="d-flex justify-content-between  align-items-center" />

            <b-card-text class="item-description">
              <h4 :class="itemView==='grid-view'?'text-center':''">
                <!--                <profile-name :location="location" />-->
              </h4>
              <location-address-link
                :data="location"
              />
            </b-card-text>
          </b-card-body>

          <!-- Product Actions -->
          <div class="item-options text-center ">
            <div class="item-wrapper" />

            <b-button
              variant="secondary"
              class="btn-cart"
              @click="locationInfo(location)"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('Details') }}</span>
            </b-button>

            <location-add-request-button
              :location="location"
            />

          </div>
        </b-card>
      </section>
      <!-- Pagination -->
      <section v-if="itemView!=='map-view' && totalLocations">
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="filters.page"
              :total-rows="totalLocations"
              :per-page="filters.perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
      <template #overlay>
        <b-spinner
          type="grow"
          label="Loading..."
        />
      </template>
    </b-overlay>
    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <location-list-left-sidebar
        :filters="filters"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      />
    </portal>
  </div>
</template>

<script>
import {
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BCard,
  BCardText,
  BPagination,
  BOverlay,
  BButton,
  BCardBody,
  BSpinner,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { watch } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'

import { formatDateTime } from '@/filters/dateTime'

import { useLocationUi } from '@/views/apps/location/useLocation'
import LocationListMapView from '@/views/apps/location/list/LocationListMapView.vue'
import LocationListTopSidebar from '@/views/apps/location/list/LocationListTopSidebar.vue'

import LocationAddressLink from '@/views/apps/location/details/LocationAddressLink.vue'
import LocationListLeftSidebar from '@/views/apps/location/list/LocationListLeftSidebar.vue'
import LocationAddRequestButton from '@/views/apps/location/details/LocationAddRequestButton.vue'
import { useFiltersSortingAndPagination, useUi, useRemoteData } from './useLocationsList'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BCard,
    BCardText,
    BPagination,
    BOverlay,
    BButton,
    BCardBody,
    BSpinner,

    // SFC
    LocationListLeftSidebar,
    LocationListTopSidebar,

    LocationListMapView,
    LocationAddressLink,
    LocationAddRequestButton,

  },
  filters: {
    formatDateTime,
  },

  setup() {
    const {
      locationEdit, locationInfo,
    } = useLocationUi()

    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useFiltersSortingAndPagination()

    const {
      itemView, itemViewOptions, totalLocations,
    } = useUi()

    const {
      locations, submittingFetch, fetchLocations,
    } = useRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    // Wrapper Function for `fetchLocations` which can be triggered initially and upon changes of filters
    const fetchFilteredLocations = () => {
      // if (filters.value.locationId) {
      //   args.location_id = filters.value.locationId
      // }
      //
      // if (sortBy.value.value) {
      //   args.sort_by = sortBy.value.value
      // }
      //
      // if (filters.value.postStatuses.length !== 0) {
      //   args.post_statuses = filters.value.postStatuses.join()
      // }
      // args.order = 'desc'
      const args = {
        // q: filters.value.q,
        sort_by: 'created_at',
        order: 'desc',
        page: filters.value.page,
        per_page: filters.value.perPage,
      }

      if (filters.value.referral) {
        args.referral = filters.value.referral
      }

      if (filters.value.referral && filters.value.managed) {
        args.managed = filters.value.managed
      }

      if (filters.value.geolocationIsActive && filters.value.lat && filters.value.lng && filters.value.locationRadius) {
        args.lat = filters.value.lat
        args.lng = filters.value.lng
        args.location_radius = filters.value.locationRadius / 1000
      }
      if (filters.value.pointPurposes.length !== 0) {
        args.purpose_ids = filters.value.pointPurposes.join()
      }
      if (filters.value.languages.length !== 0) {
        args.langs = filters.value.languages.join()
      }
      fetchLocations(args)
        .then(response => {
          const { total } = response.data.meta

          locations.value = response.data.data
          totalLocations.value = total
          // filterOptions.pointPurposes.value = purposes
        })
    }

    watch([filters, sortBy], () => {
      fetchFilteredLocations()
    }, {
      deep: true,
    })

    watch([totalLocations], () => {
      if (totalLocations.value) {
        itemView.value = 'grid-view'
      } else {
        itemView.value = 'map-view'
      }
    })
    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalLocations,

      locationEdit,
      locationInfo,

      // useServiceRequestsRemoteData
      submittingFetch,
      locations,

      fetchLocations,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.search-point.form-control{
  height: auto;
}
.actions{
  position: absolute;
  bottom: 0;
  width: 100%;
}
.ecommerce-application .grid-view .ecommerce-card .item-img {
  min-height:0;
}
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.ecommerce-application .grid-view .ecommerce-card .item-description {
  white-space: normal;
}
@media all and (min-width:768px) and (max-width: 1440px) {
  .ecommerce-application .grid-view{
    grid-template-columns: 1fr 1fr;
  }
}
</style>
