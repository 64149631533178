<template>
  <div>
    <b-button
      variant="primary"
      :tag="tag"
      class="btn-cart"
      block
      @click="locationAddRequest(location)"
    >
      <feather-icon
        icon="PhoneCallIcon"
        class="mr-50"
      />
      <span class="align-middle">{{ $t('Request services') }}</span>
    </b-button>
  </div>

</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

import { useLocationUi } from '@/views/apps/location/useLocation'

export default {
  components: {
    BButton,
  },

  props: {
    location: {
      type: Object,
      required: true,
      default: () => {},
    },
    tag: {
      type: String,
      required: false,
      default: () => 'button',
    },
  },

  setup() {
    const {
      locationAddRequest,
    } = useLocationUi()
    return {
      locationAddRequest,
    }
  },
}

</script>
